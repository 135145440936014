<template>
  <BittsModalTwo
    :loading="sourceLoading || loading"
    :use-mask-to-close="true"
    class="c-create-legacy-population_modal"
    container-class="w-full lg:max-w-[1400px]"
    @closed="close"
    @action="handleAction"
    :disabled="saving || loading"
    :action-loading="saving"
    :hide-footer="true"
  >
    <template #header>
      <div class="c-create-population_header">
        <h3 class="c-create-population_title"> {{ title }}</h3>
        <BittsTag
          v-if="recordCount !== null"
          color="secondary"
          size="small"
          variant="rounded"
        >
          {{ `${recordCount} ${pluralize(recordCount, 'record')}` }}
        </BittsTag>
      </div>
    </template>
    <PopulationDetail ref="populationDetail" :population-id />
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsModalTwo, BittsTag } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import PopulationDetail from '@/components/populations/PopulationDetail.vue';

import useDataSourceSettings from '@/composables/useDataSourceSettings';
import { getPopulationTypeName } from '@/constants/standard_populations';
import { StandardPopulationType } from '@/types/populations';

import usePopulationModal from '../composables/usePopulationModal';

const { populationId } = defineProps<{
  populationId: number;
}>();
const router = useRouter();
const route = useRoute();
const { loading: sourceLoading, saving } = useDataSourceSettings();

const { recordCount, loading, handleSavePopulation, populationTypeName } =
  usePopulationModal({ feedId: null });

const standardPopType = computed(() => route.query.standardType);
const popName = computed(() =>
  getPopulationTypeName(standardPopType.value as StandardPopulationType),
);
const title = computed(
  () =>
    `${populationId ? 'Edit' : 'Create'} "${popName.value || populationTypeName.value}" Population`,
);

async function close() {
  await router.push({ name: 'onboarding_define_populations' });
}
async function handleAction() {
  await handleSavePopulation();
}
</script>

<style lang="pcss">
.c-create-legacy-population_modal.o-bitts-modal.bitts-modal--primary {
  .o-bitts-modal__header {
    @apply !p-24 flex-row border-b border-neutral-border;
  }
}
</style>

<style lang="pcss" scoped>
.c-create-legacy-population_modal :deep(.o-bitts-modal__content) {
  @apply p-0 mt-20 !mx-12 !mb-12;
}
.c-create-population_header {
  @apply flex gap-8;
}
.c-create-population_title {
  @apply text-neutral-text-strong font-bold text-lg w-full;
}

:deep(.population-detail .sidebar) {
  @apply relative top-auto;
}

:deep(.population-detail .population-table) {
  @apply py-0 pr-0 m-0;
}

:deep(.population-detail .bitts-table) {
  @apply h-full;
}
</style>
