<template>
  <div class="c-field-presets-tab">
    <BittsAlert
      color="info"
      message="Create presets of fields that you and your team will be using for data sharing."
    />
    <BittsButton
      text="Create preset"
      type="neutral"
      variant="outline"
      class="c-field-presets-tab__create-btn"
      :left-icon="['far', 'plus']"
      @click="openModal()"
      :disabled="!ready"
    />
    <BittsLoading :is-loading="!ready">
      <BittsContainer no-padding>
        <template v-for="(preset, index) in sortedPresets" :key="preset.id">
          <div
            class="c-field-presets-tab__preset-row"
            :class="{
              'c-field-presets-tab__preset-row--first': index === 0,
              'c-field-presets-tab__preset-row--last':
                index + 1 === sortedPresets.length,
            }"
            @click="openModal(preset.id)"
          >
            <div class="flex items-center gap-12">
              <div class="font-bold">{{ preset.name }}</div>
              <BittsTag
                v-if="preset.preset_type === 'crossbeam_default'"
                text="Default"
                color="neutral"
              />
            </div>
            <div class="flex items-center gap-12">
              <BittsTag
                :text="`${preset.source_fields.length} ${pluralize(preset.source_fields.length, 'field')}`"
                color="secondary"
              />
              <FontAwesomeIcon :icon="['fak', 'chevron-right']" />
            </div>
          </div>
          <BittsDivider v-if="index + 1 < sortedPresets.length" class="my-0" />
        </template>
      </BittsContainer>
    </BittsLoading>
  </div>
</template>

<script setup lang="ts">
import {
  BittsAlert,
  BittsButton,
  BittsContainer,
  BittsDivider,
  BittsLoading,
  BittsTag,
} from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';

import { useFieldPresets } from '@/composables/useFieldPresets';
import { useDataSharePresetsStore } from '@/stores';

type Props = {
  feedId: number;
  sourceId?: number;
};
const { feedId, sourceId } = defineProps<Props>();

const { sortedPresets, openModal } = useFieldPresets({ feedId, sourceId });
const { ready } = storeToRefs(useDataSharePresetsStore());
</script>

<style lang="pcss">
.c-field-presets-tab {
  @apply flex flex-col gap-20;
  .c-loading {
    @apply max-h-[50px];
  }
}
.c-field-presets-tab__create-btn {
  @apply size-fit;
}
.c-field-presets-tab__preset-row {
  @apply flex justify-between px-20 py-16 cursor-pointer;
  &:hover {
    @apply bg-neutral-background-weak;
  }
  &--first {
    @apply rounded-t-16;
  }
  &--last {
    @apply rounded-b-16;
  }
}
</style>
